import {currencyFormatCustom} from "../helpers";
import api from '../compenents/ApiController';
const config = window.configCrypt;

const BNB_TOKEN_BALANCE = '0x0000000000000000000000000000000000000000';

let web3Component;

const connectButton = document.querySelector('.button-dropdown_wrapper.wallet-connect-btn');
const disconnect_button = document.querySelector('.wallet-disconnect');

window.onload = async () => {
  if (location.pathname.includes('/shop/')) return;
  web3Component = new window.web3Component(config);
  dropdown();
  const isConnected = window.web3Component.isConnected();
  if (!connectButton) return;
  if (!connectButton) return;
  if (isConnected) {
    await check_user();
    await auto_connect();
  }
  disconnect();
  await manual_connect();
};

const manual_connect = async () => {
  if (!connectButton) return;
  connectButton.addEventListener('click', async () => {
    const isConnected = window.web3Component.isConnected();
    if (isConnected) return;
    web3Component.connectWallet().then(async () => {
      await generateData();
      localStorage.setItem('user_id_bak', await get_user_id());
      disconnect();
    });
  });
}

const auto_connect = async () => {
  web3Component.connectWallet().then(async () => {
    await generateData();
  }).catch((e) => {
    console.error(e);
  })
}

const generateData  = async () => {
  const {vid, bnb, locks} = await get_currency_data();

  const currencies = generate_currency_array(vid, bnb, locks);

  const wrapper = document.querySelector('.dropdown-wallet-connect-wrapper');
  const dropWrapper = document.querySelector('.custom-vidi-menu-dropdown');
  if (!dropWrapper) return;
  if (!wrapper) return;
  dropWrapper.classList.add('wallet-expanded');
  wrapper.innerHTML = generateElement(currencies);
  wrapper.classList.remove('hide');
  const disconnect = document.querySelector('.wallet-disconnect');
  if (disconnect) disconnect.classList.remove('hide');

  const status = connectButton.querySelector('.wallet-connection-status');

  if (status) {
    status.classList.remove('disconnected');
    status.classList.add('connected');
  }

  modal_control(currencies);
}

const disconnect = () => {
  disconnect_button.addEventListener('click', async () => {
    const isConnected = window.web3Component.isConnected();
    if (!isConnected) return;
    const wrapper = document.querySelector('.dropdown-wallet-connect-wrapper');
    wrapper.classList.toggle('hide');
    await web3Component.disconnectWallet();
    localStorage.removeItem('walletconnect');
    disconnect_button.classList.add('hide');
    const status = connectButton.querySelector('.wallet-connection-status');

    if (status) {
      status.classList.remove('connected');
      status.classList.add('disconnected');
    }
    const dropWrapper = document.querySelector('.custom-vidi-menu-dropdown');
    if (dropWrapper) dropWrapper.classList.remove('wallet-expanded');
  })
}

let active = false;
const dropdown = () => {
  const trigger = document.querySelector('.custom-vidi-dropdown-trigger');
  if (!trigger) return;
  trigger.addEventListener('click', () => {
    const menu = document.querySelector('.custom-vidi-menu-dropdown');
    if (!menu) return;
    menu.classList.toggle('hide');
    if (!active) setTimeout(() => document.addEventListener('click',  outOfVidDropdown), 16);
    active = true;
  })

  // const notification_dropdown = document.querySelector('.user-menu__wrapper.notification-button');
  // if (!notification_dropdown) return;
  // notification_dropdown.addEventListener('click', () => {
  //   const _drop = document.querySelector('.user-menu__wrapper.notification-button');
  //   if (_drop && _drop.ariaExpanded) {
  //     const _wrapper = document.querySelector('.dropdown-menu-custom');
  //     if (_wrapper && _wrapper.classList.contains('show-custom')) _wrapper.classList.remove('show-custom');
  //     const vidi_wallet = document.querySelector('.custom-vidi-menu-dropdown');
  //     if (vidi_wallet && !vidi_wallet.classList.contains('hide')) vidi_wallet.classList.add('hide');
  //   }
  // })
}

const outOfVidDropdown = (e) => {
  const _wrapper = document.querySelector('.custom-vidi-menu-dropdown');
  if (_wrapper && !_wrapper.classList.contains('hide')) {
    const pos = _wrapper.getBoundingClientRect();
    if ((e.pageX < pos.left) || (e.pageX > pos.right) ||(e.pageY > pos.bottom) || (e.pageY < pos.top)) {
      _wrapper.classList.add('hide');
      document.removeEventListener('click', outOfVidDropdown);
      active = false;
    }
  }
  setTimeout(() => document.removeEventListener('click', outOfVidDropdown), 16)
};


const modal_control = (currencies) => {
  const modal_placeholder = document.querySelector('.modal-wrapper');
  if (!modal_placeholder) return;
  modal_placeholder.innerHTML = modal_insert(currencies);
  show_modal();
  hide_modal();
}

const show_modal = () => {
  const modalTriggers = document.querySelectorAll('.open-vid-modal');
  [...modalTriggers].forEach(el => el.addEventListener('click', () => {
    const modal_wrapper = document.querySelector('.blur-portal__vid-info');
    if (modal_wrapper && !modal_wrapper.classList.contains('active')) {
      modal_wrapper.classList.add('active');
      hide_dropdown();
      setTimeout(() => modal_wrapper.classList.add('animate'), 16);
    }
  }));
}

const hide_modal = () => {
  const modal_wrapper = document.querySelector('.blur-portal__vid-info');
  const close_button = modal_wrapper.querySelector('.modal__close');
  if (close_button) close_button.addEventListener('click', () => {
    const modal_wrapper = document.querySelector('.blur-portal__vid-info');
    if (modal_wrapper && modal_wrapper.classList.contains('active')) {
      modal_wrapper.classList.remove('animate');
      setTimeout(() => modal_wrapper.classList.remove('active'), 300)
    }
  })
}

const hide_dropdown = () => {
  const dropdown = document.querySelector('.wallet-expanded');
  dropdown.classList.add('hide');
}

const generate_currency_array = (vid, bnb, locks) => {
  locks.forEach(lock => lock.amount = +lock.amount);

  const private_locks = {
    data: locks.filter(lock => lock.amount === config.PRIVATE_AMOUNT)
  }

  const seed_locks = {
    data: locks.filter(lock => lock.amount === config.SEED_AMOUNT)
  }

  if (private_locks.data.length > 0) {
    private_locks.sum = sum_of_array(private_locks.data, 'amount');
    private_locks.released = sum_of_array(private_locks.data, 'released');
    private_locks.percent = private_locks.released / private_locks.sum * 100;
    private_locks.cliff = private_locks.data[0].cliff / 24 + ' days';
  }

  if (seed_locks.data.length > 0) {
    seed_locks.sum = sum_of_array(seed_locks.data, 'amount');
    seed_locks.released = sum_of_array(seed_locks.data, 'released');
    seed_locks.percent = seed_locks.released / seed_locks.sum * 100;
    seed_locks.cliff = seed_locks.data[0].cliff / 24 + ' days';
  }

  const currencies = [{
    type: 'vid',
    headline: 'Баланс:',
    sum: vid + ' V',
  }, {
    type: 'bnb',
    headline: 'Баланс:',
    sum: bnb + ' BNB',
  }];

  if (private_locks.sum) {
    currencies.push(
      {
        type: 'private',
        heading: 'Private',
        headline: 'Private',
        description: 'Заморожено',
        sum: currencyFormatCustom(private_locks.sum, 'VID').replace('ID', ''),
      }
    );
  }

  if (seed_locks.sum) {
    currencies.push(
      {
        type: 'seed',
        heading: 'Seed',
        headline: 'Seed',
        description: 'Заморожено',
        sum: currencyFormatCustom(private_locks.sum, 'VID').replace('ID', ''),
      }
    );
  }
  return currencies;
}

const get_currency_data = async () => ({
  vid: await web3Component.getTokenBalance(),
  bnb: await web3Component.getTokenBalance(BNB_TOKEN_BALANCE),
  locks: await web3Component.getUserLocks()
});

const check_user = async () => {
  const user_id_bak = +localStorage.getItem('user_id_bak') || 0;
  const user_id = await get_user_id();

  if (+user_id_bak !== user_id && localStorage.hasOwnProperty('walletconnect')) {
    await web3Component.disconnectWallet();
    localStorage.removeItem('walletconnect')
    return true
  }
  return false;
}

const get_user_id = async () => {
  try {
    const request = await api.get({href: '/getUserBySession', body: {}})
    if (request.message) return console.error(request.message);
    return request.data;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

const sum_of_array = (array, el_name) => {
  return array.reduce((acc, el) => {
    acc += +el[el_name];
    return acc;
  }, 0);
}

const generateElement = (currencies) => currencies.map(el => `
        <div class="button-dropdown_wrapper d-flex justify-content-between">
            <div>
            <p class="body-15">${el.headline}</p>
            ${el.description ? `<p class="body-13 color-300">${el.description}</p>` : ''}
            </div>
            <div class="d-flex flex-row">
                <p class="body-15 me-2">${el.sum}</p>
                ${el.heading ? '<img src="/img/icon/question.svg" width="24" height="24" alt="question" class="open-vid-modal pointer">' : ''}
            </div>
        </div>
        `).join('');

const modal_insert = (currencies) => {
  return `
    <div
    class="blur-portal blur-portal__vid-info align-items-center justify-content-center">
    <div class="container">
        <div
            class="blur-modal blur-portal__email-activate col-6 d-flex align-self-center m-auto flex-column">
            <div class="modal__close d-flex align-items-center justify-content-center">
                <div class="icon__wrapper pointer">
                    <img src="/img/icon/close.svg" width="24" height="24" alt="Закрыть">
                </div>
            </div>
            <div class="modal-heading d-flex flex-row align-items-center">
                <p class="h2 mb-0 d-flex justify-content-center align-items-center">Баланс счёта!</p>
            </div>
            <div class="spacer-32"></div>
            <div class="tariff-modal-body">
            ${currencies.map(el => el.heading && `
            <p class="body-18-bold">${el.heading}</p>
            <div class="spacer-8"></div>
                <div class="tariff-detailed-row">
                        <div class="tariff-detail-name">
                            <div class="icon-bg-warning me-2">
                               <img src="/img/alert/warning.svg" width="24" height="24" alt="внимание">
                            </div>
                            <p class="body-15">Заморожено</p>
                        </div>
                        <div class="horizontal-line"></div>
                        <p class="body-18">${el.sum}</p>
                    </div>
            </div>
            `).join('')}
        </div>
    </div>
</div>`
}